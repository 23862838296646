/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from "react";
import Hls, { SubtitleFragProcessedData } from "hls.js";
import { Skeleton } from "@nextui-org/skeleton";
import { Card } from "@nextui-org/card";
import {
  AlertCircleIcon,
  CirclePlusIcon,
  LanguagesIcon,
  Minus,
  Plus,
  // Volume,
  // Volume2,
  // VolumeX,
} from "lucide-react";
import { Button, ButtonGroup } from "@nextui-org/button";
import { Tooltip } from "@nextui-org/tooltip";

import { CircularProgress } from "@nextui-org/progress";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import * as Sentry from "@sentry/react";
// import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import loader from "../proivder/hlsCustomLoader";

import Captions from "./captions";

import StreamSettings from "./streamSettings";

const authProvider = AuthProvider.getInstance();

import { useToast } from "./ui/use-toast";

import { Channel, ChannelProvider, Status } from "@/proivder/channelProvider";
import useAppStore from "@/store/store";
import { BASE_VIDEO_URL } from "@/constants/urls";
import { Logger } from "@/lib/logger";
import { AuthProvider } from "@/proivder/authProvider";

interface VideoPlayerProps {
  channel: Channel | null;
  channels: Channel[];
  onDelete: (channel: Channel) => void;
  onUpdate: (channel: Channel) => void;
  onExport: (channel: Channel) => void;
  onReady: () => void;
}

export interface Subtitle {
  text: string;
  start: number;
  end: number;
}

export interface SubtitleTrack {
  index: number;
  label: string;
  subtitles?: Subtitle[];
}

let hls: Hls | null = null;

let interval: any;

export default function VideoPlayer({
  channel,
  channels,
  onReady,
}: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const inFullscreen = useRef(false);

  const indexRef = useRef<number>(0);

  const retryRef = useRef<number>(0);

  const [fontSize, setFontSize] = useState(18);

  const addCaptions = useAppStore((state) => state.addCaptions);
  const setCurrentTime = useAppStore((state) => state.setCurrentTime);
  const setVideoPlayer = useAppStore((state) => state.setVideoPlayer);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [isMuted, setIsMuted] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [, setIsMultipleSubtitles] = useState(false);

  const [subtitleTracks, setSubtitleTracks] = useState<SubtitleTrack[]>([]);

  const [selectedCaption] = useState<number | null>(null);

  const [hideCaptions] = useState<number[]>([]);

  const [showControls, setShowControls] = useState(false);

  const controlInterval = useRef<any>();

  const [voiceOver, _] = useState(false);

  const currentSubtitles = useAppStore((state) => state.currentSubtitles);

  const [channelTranslations, setChannelTranslations] = useState<string[]>([]);

  const [, setTimer] = useState(0);

  const languageProvider = useAppStore((state) => state.languageProvider);
  const langauges = useAppStore((state) => state.languages);

  const updateChannel = useAppStore((state) => state.updateChannel);

  const lastSubtitleText = useRef<string | null>(null);

  const setVideoBuffer = useAppStore((state) => state.setVideoBuffer);
  const setAudioBuffer = useAppStore((state) => state.setAudioBuffer);
  const videoBuffer = useAppStore((state) => state.videoBuffer);
  const audioBuffer = useAppStore((state) => state.audioBuffer);

  const subtitleFragments = useRef<SubtitleFragProcessedData[]>([]);

  const translateId = useRef<SubtitleTrack | null>(null);

  const [langs, setLangs] = useState<string[]>([]);

  const setCurrentSubtitles = useAppStore((state) => state.setCurrentSubtitles);

  const { toast } = useToast();

  function getSubtitleText(lines: string[], num?: number) {
    const _num = num !== undefined ? num - 1 : lines.length - 1;
    // let text = "";

    const text = lines[_num];

    if (text === undefined) return "";

    if (text && text.includes("X-TIMESTAMP-MAP")) {
      return "";
    }

    if (text && text.includes("WEBVTT")) {
      return "";
    }

    if (text.trim().length === 0) {
      return getSubtitleText(lines, _num);
    }

    return text;
  }

  // function formatTime(time: number) {
  //   const hours = Math.floor(time / 3600);
  //   const minutes = Math.floor((time % 3600) / 60);
  //   const seconds = Math.floor(time % 60);

  //   return `${hours}:${minutes}:${seconds}`;
  // }

  // only keep 6 last subtitle fragments in memory
  function addSubtitleFragment(data: SubtitleFragProcessedData) {
    subtitleFragments.current.push(data);

    if (subtitleFragments.current.length > 6) {
      subtitleFragments.current.shift();
    }
  }

  function updateVideoTime() {
    if (videoRef.current?.currentTime) {
      setCurrentTime(videoRef.current.currentTime);
      // update component state to rereender

      //  setSubtitleTracks(subtitleTracks);
      setTimer(videoRef.current.currentTime);
    }
  }

  function loadVideo() {
    if (interval) {
      clearInterval(interval);
    }
    const isMultipleSubtitle = languageProvider.isMultipleSubtiles();

    setIsMultipleSubtitles(isMultipleSubtitle);

    setSubtitleTracks([]);
    if (videoRef.current) {
      videoRef.current.removeEventListener("timeupdate", updateVideoTime);
    }

    if (hls) {
      hls.destroy();
    }

    setIsLoaded(false);

    setErrorMessages([]);

    if (videoRef.current && channel) {
      videoRef.current.playsInline = true;

      videoRef.current.addEventListener("timeupdate", updateVideoTime);

      setVideoPlayer(videoRef.current);

      var video = videoRef.current;
      const videoSrc = BASE_VIDEO_URL + channel.unique_name;

      video.addEventListener("loadeddata", () => {
        setIsLoaded(true);
      });

      if (Hls.isSupported()) {
        hls = new Hls({
          autoStartLoad: true,

          loader: loader,
          startLevel: 0, // Start at the lowest quality level available
          maxBufferLength: 45, // Increase buffer length to 20 seconds
          maxBufferSize: 120 * 1000 * 1000, // Increase buffer size to 120MB
          maxBufferHole: 0.1, // Reduce max buffer hole for tighter buffer control
          fragLoadingMaxRetry: 5, // Increase retry count to 5
          fragLoadingRetryDelay: 2000, // Increase retry delay to 2000 ms
          fragLoadingMaxRetryTimeout: 120000, // Increase max retry timeout to 120 seconds
          abrBandWidthFactor: 0.8, // More conservative ABR settings
          abrBandWidthUpFactor: 0.9, // Less aggressive in increasing bitrate
          lowLatencyMode: false, // Disable low latency mode
          backBufferLength: 45, // Retain 30 seconds of back buffer
          //  autoLevelCapping: 1, // Cap to one level above the lowest quality
          startFragPrefetch: true, // Enable prefetching of next fragment
          enableWorker: true, // Use web workers for better performance
          //   recoverMediaError: true, // Enable automatic recovery from media errors
          debug: false, // Disable debug logs in production
        });

        hls.on(Hls.Events.BUFFER_APPENDING, (_, data) => {
          if (data.type === "video") {
            onReady();
            // videoBuffer
            //   ? setVideoBuffer([...videoBuffer, data.data])
            //   : setVideoBuffer([data.data]);
            videoBuffer.push(data.data);
            setVideoBuffer(videoBuffer);
          }
          if (data.type === "audio") {
            // audioBuffer
            //   ? setAudioBuffer([...audioBuffer, data.data])
            //   : setAudioBuffer([data.data]);

            audioBuffer.push(data.data);
            setAudioBuffer(audioBuffer);
          }
        });

        hls.loadSource(videoSrc);
        hls.attachMedia(video);

        if (isMultipleSubtitle) {
          handleMultipleSubtitle(hls, video);
        }

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
          localStorage.setItem("lastVideoId", channel._id.toString());
        });

        hls.on(Hls.Events.ERROR, function (_, data) {
          if (data.fatal) {
            Sentry.captureException(data);
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                setErrorMessages(["A network / server error, Retrying ..."]);

                setTimeout(() => {
                  retryRef.current += 1;
                  hls?.startLoad();
                }, 1000);
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                setErrorMessages([
                  "An error occurred while decoding the video.",
                ]);
                break;
              default:
                setErrorMessages([
                  "An unknown error occurred while loading the video.",
                ]);
                break;
            }
          }
        });

        // check if hls loaded after network error

        hls.on(Hls.Events.BUFFER_APPENDED, function () {
          onReady();
          retryRef.current = 0;
          setErrorMessages([]);
        });

        hls.on(Hls.Events.LEVEL_LOADED, function () {
          retryRef.current = 0;
          setErrorMessages([]);
        });

        setTimeout(() => {
          setIsMuted(false);
        }, 500);
      }

      // HLS.js is not supported on platforms that do not have Media Source
      // Extensions (MSE) enabled.
      //
      // When the browser has built-in HLS support (check using `canPlayType`),
      // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
      // element through the `src` property. This is using the built-in support
      // of the plain video element, without using HLS.js.
      else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;

        videoRef.current.addEventListener("loadedmetadata", () => {
          video.play();
        });
      }
    }
  }

  function handleMultipleSubtitle(hls: Hls, video: HTMLVideoElement) {
    video.addEventListener("fullscreenchange", () => {
      // check if video is in fullscreen mode
      if (document.fullscreenElement) {
        //videoRef.current?.setAttribute("controls", "true");
        inFullscreen.current = true;

        if (hls) {
          hls!.subtitleDisplay = true;
          //  hls.subtitleTrack = selectedCaption || 0;
        }

        //  alert("fullscreen");
        if (selectedCaption != null) {
        }
      } else {
        //videoRef.current?.setAttribute("controls", "true");
        inFullscreen.current = false;

        if (hls) {
          //  hls!.subtitleDisplay = false;
        }

        // alert("not fullscreen");
      }
    });
    hls.subtitleDisplay = false;

    let items: SubtitleTrack[] = [];

    hls.once(Hls.Events.SUBTITLE_TRACK_LOADED, function () {
      if (!hls?.subtitleTracks) return;

      setSubtitleTracks([]);

      for (let i = 0; i < hls?.subtitleTracks.length; i++) {
        let track = hls.subtitleTracks[i];
        // track.mode = "hidden";
        const languages = localStorage.getItem("selected_languages");

        //   video.textTracks[i].mode = "hidden";

        if (languages) {
          const selectedLanguages = languageProvider.getSelectedLanguages();

          languageProvider.setLanguages(track.lang!);
          if (track.name === "transcript") {
            hls!.subtitleTrack! = i;
            hls.subtitleDisplay = true;
            languageProvider.setSelectedLanguages([
              ...selectedLanguages,
              channel!.audio[0].lang,
            ]);

            setSubtitleTracks((prev) => {
              items = [...prev, { index: i, label: channel!.audio[0].lang }];
              return items;
            });
          }

          if (track.lang && selectedLanguages.includes(track.lang)) {
            setSubtitleTracks((prev) => {
              items = [
                ...prev.filter((t) => t.index !== i),
                { index: i, label: track.lang! },
              ];

              return items;
            });

            addCaptions([{ index: i, label: track.lang, subtitles: [] }]);

            const _langs = languageProvider.getChannelLanguages(
              channel!._id,
              subtitleTracks.map((item) => item.label),
              channel!.audio[0].lang
            );

            setLangs(_langs);

            items.push({ index: i, label: track.lang, subtitles: [] });
          }
        }
      }

      const _langs = languageProvider.getChannelLanguages(
        channel!._id,
        subtitleTracks.map((item) => item.label),
        channel!.audio[0].lang
      );

      setCurrentSubtitles(items);
      translateId.current =
        items.find((item) => item.label === _langs[1]) ?? items[1];

      hls?.on(
        Hls.Events.SUBTITLE_FRAG_PROCESSED,
        async function (_, data: SubtitleFragProcessedData) {
          //   console.log(data.frag);
          //  // await handleSubtitleFragment(data, video);
          addSubtitleFragment(data);
          if (translateId.current) {
            await handleSubtitleFragment(data, video, translateId.current!);
          }
        }
      );
    });
  }

  // fetch all subtitle fragments and check if time is less video current time
  async function fetchAllSubtitleFragments() {
    if (!subtitleFragments.current) return;

    for (let i = 0; i < subtitleFragments.current.length; i++) {
      const data = subtitleFragments.current[i];

      if (
        data.frag.end > videoRef.current!.currentTime &&
        translateId.current
      ) {
        await handleSubtitleFragment(
          data,
          videoRef.current!,
          translateId.current!
        );
      }
    }
  }

  async function checkAndUpdateModel(lang: string) {
    const modelKey = `${channel?.audio[0].lang}_${lang}`;
    const reverseModelKey = `${lang}_${channel?.audio[0].lang}`;
    const models = await ChannelProvider.getTranslateModels();
    // console.log(res);
    const model = models.find((item) => item.key === modelKey);
    const reverseModel = models.find((item) => item.key === reverseModelKey);

    if (model && !model.load) {
      ChannelProvider.updateTranslateModels({ ...model, load: true });
    }

    if (reverseModel && !reverseModel.load) {
      ChannelProvider.updateTranslateModels({ ...reverseModel, load: true });
    }
  }

  async function handleSubtitleFragment(
    data: SubtitleFragProcessedData,
    video: HTMLVideoElement,
    track?: SubtitleTrack
  ) {
    const startTime = performance.now();

    let level = 0;

    if (track) {
      level = track.index;
    }

    let url = data.frag.url;

    if (track) {
      url = url.replace("lang=auto", `lang=${track.label}`);
    }

    // http request for fetching the subtitle
    const response = await fetch(url);

    const endTime = performance.now();
    const responseTime = endTime - startTime;

    Sentry.metrics.distribution("subtitle_fragments_load_time", responseTime, {
      tags: { type: "important" },
      unit: "millisecond",
    });

    if (responseTime > 1000) {
      Logger.log("Response time is too long: " + responseTime + "ms");
    }

    const text = await response.text();

    // check if subtitle response time is too long and missed the video time to show the subtitle text , then log it

    const lines = text.split("\n");

    const _text = getSubtitleText(lines);

    if (data.frag.start - video.currentTime > 1 && _text.trim().length > 0) {
      Sentry.captureMessage(
        "Subtitle response time is too long: " +
          responseTime +
          "ms" +
          " and missed the video time to show the subtitle text" +
          " " +
          "video time: " +
          video.currentTime +
          " " +
          "subtitle start time: " +
          data.frag.start +
          " " +
          "subtitle end time: " +
          data.frag.end +
          " " +
          " Channle: " +
          channel?.name,
        "debug"
      );
    }

    const hasPairTranscript =
      subtitleTracks[0] &&
      subtitleTracks[0].subtitles?.find(
        (s) => s.start <= data.frag.start && s.end >= data.frag.end
      );

    if (hasPairTranscript && _text.trim().length === 0 && level !== 0) {
      Sentry.captureMessage(
        "Subtitle translate segment is empty and has a pair transcript" +
          " " +
          "video time: " +
          video.currentTime +
          " " +
          "subtitle start time: " +
          data.frag.start +
          " " +
          "subtitle end time: " +
          data.frag.end +
          " " +
          " Channle: " +
          channel?.name,

        "debug"
      );

      Logger.log(
        "Subtitle translate segment is empty and has a pair transcript" +
          " " +
          "video time: " +
          video.currentTime +
          " " +
          "subtitle start time: " +
          data.frag.start +
          " " +
          "subtitle end time: " +
          data.frag.end +
          " " +
          " Channle: " +
          channel?.name
      );
    }

    if (_text.trim().length === 0) {
      return;
    }

    let subtitle: Subtitle = {
      text: _text,
      start: data.frag.start,
      end: data.frag.end ? data.frag.end : data.frag.start + data.frag.duration,
    };

    setSubtitleTracks((prev) => {
      const tracks = prev.map((t) => {
        if (t.index === level) {
          return {
            ...t,
            subtitles: t.subtitles ? [...t.subtitles, subtitle] : [subtitle],
          };
        }

        return t;
      });

      addCaptions(tracks);

      setCurrentSubtitles(tracks);

      return tracks;
    });
  }

  useEffect(() => {
    if (subtitleTracks.length) {
      startSetTrackInterval();
    }

    return () => {
      clearInterval(interval);
    };
  }, [subtitleTracks]);

  useEffect(() => {
    // set font size
    const size = localStorage.getItem("fontSize");

    if (size) {
      setFontSize(parseInt(size));
    }

    return () => {
      setCurrentSubtitles([]);
      addCaptions([]);
    };
  }, []);

  function showCurrentSubtitle(index: number) {
    if (!subtitleTracks[index] || !subtitleTracks[index].subtitles) return;

    if (!videoRef.current) return;

    let currentTime = hls?.media?.currentTime!;

    // sort from lower to higher
    const subtitles = subtitleTracks[index].subtitles.sort(
      (a, b) => a.start - b.start
    );

    let subtitleNext = subtitles.find((s) => s.start > currentTime);

    let subtitle = subtitles.reduce((acc, s) => {
      if (
        s.text.trim().length > 0 &&
        s.start <= currentTime &&
        (subtitleNext ? subtitleNext.start > currentTime : true)
      ) {
        return s;
      }

      return acc;
    }, subtitles[0]);

    if (subtitle) {
      if (subtitle.text !== lastSubtitleText.current && voiceOver) {
        try {
          const utterance = new SpeechSynthesisUtterance(subtitle.text);

          utterance.lang = subtitleTracks[index].label;
          const textDuration = subtitle.end - subtitle.start;

          // change the rate of the speech base of the subtitle duration and subtitle text length
          utterance.rate = calculateSpeechRate(subtitle.text, textDuration);
          // utterance.pitch = 1.1;

          const voices = speechSynthesis.getVoices();

          // Find and set a male voice
          const maleVoice = voices.find(
            (voice) => voice.name.includes("Male") || voice.name.includes("Man")
            // ||
            // voice.gender === "male"
          );

          if (maleVoice) {
            utterance.voice = maleVoice;
          } else {
            // Fall back to a default voice if a male voice is not found
            utterance.voice = voices[0];
          }

          videoRef.current.volume = 0.3;

          // conver subtitle.
          //  utterance.rate =
          speechSynthesis.cancel();
          window.speechSynthesis.speak(utterance);
        } catch (e) {
          console.log(e);
        }
      }

      lastSubtitleText.current = subtitle.text;

      return subtitle.text;
    }
  }

  // Function to estimate syllable count
  function estimateSyllables(word: string) {
    word = word.toLowerCase();
    if (word.length <= 3) return 1;
    word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, "");
    word = word.replace(/^y/, "");
    const syllableMatch = word.match(/[aeiouy]{1,2}/g);

    return syllableMatch ? syllableMatch.length : 1;
  }

  // Function to estimate total syllables in a text
  function countSyllables(text: string) {
    return text
      .split(/\s+/)
      .reduce((count, word) => count + estimateSyllables(word), 0);
  }

  // Function to calculate the speech rate
  function calculateSpeechRate(
    text: string,
    durationInSeconds: number,
    baseWPM = 175
  ) {
    const syllables = countSyllables(text);
    const syllablesPerSecond = (baseWPM / 60) * 1.4; // Average of 1.4 syllables per word
    const rate = syllables / (durationInSeconds * syllablesPerSecond);

    // Ensure the rate is within the allowed range
    return Math.min(Math.max(rate, 1), 1.3);
  }

  // 1 -> 3 || 3 -> 5 ||  8 -> 10

  function startSetTrackInterval() {
    return;
    interval = setInterval(() => {
      const _langs = languageProvider.getChannelLanguages(
        channel!._id,
        subtitleTracks.map((item) => item.label),
        channel!.audio[0].lang
      );

      setLangs(_langs);
      if (hls && !inFullscreen.current) {
        //setSelectedSubtitleTrack(subtitleTracks[indexRef.current]);
        if (indexRef.current < _langs.length - 1) {
          const index = subtitleTracks.findIndex((t) => t.label === _langs[1]);

          indexRef.current = index == -1 ? 0 : index;

          hls.subtitleTrack = indexRef.current;
        } else {
          indexRef.current = subtitleTracks.findIndex(
            (t) => t.label === _langs[0]
          );

          hls.subtitleTrack = indexRef.current;
        }
      }
    }, 500);
  }

  function changeSubtitleTrack() {
    if (interval) {
      clearInterval(interval);

      subtitleTracks.forEach((_, index) => {
        if (index !== 0) {
          setSubtitleTracks((prev) => {
            const tracks = prev.map((t) => {
              if (t.index === index) {
                return {
                  ...t,
                  subtitles: [],
                };
              }

              return t;
            });

            addCaptions(tracks);

            setCurrentSubtitles(tracks);

            return tracks;
          });
        }
      });
    }

    startSetTrackInterval();
  }

  function handleFontSize(type: "increase" | "decrease") {
    let newFontSize = fontSize;

    if (type === "increase") {
      newFontSize += 2;
    } else {
      newFontSize -= 2;
    }

    if (newFontSize < 8 || newFontSize > 48) {
      return;
    }

    setFontSize(newFontSize);
    localStorage.setItem("fontSize", newFontSize.toString());
  }

  useEffect(() => {
    setChannelTranslations(channel?.translations || []);
    // check if channel is active
    if (!channel) {
      return;
    }
    if (!channel!.active || channel!.status !== Status.Recording) {
      retryRef.current = 5;

      setErrorMessages(["Channel is not Active"]);

      return;
    }

    loadVideo();

    languageProvider.addEventListener(
      "selectedLanguagesChanged",
      changeSubtitleTrack
    );
    languageProvider.addEventListener(
      "multipleSubtitlesChanged",
      changeSubtitleTrack
    );

    return () => {
      setSubtitleTracks([]);
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", updateVideoTime);
      }

      if (hls) {
        hls.destroy();
      }

      languageProvider.removeEventListener(
        "selectedLanguagesChanged",
        changeSubtitleTrack
      );
      languageProvider.removeEventListener(
        "multipleSubtitlesChanged",
        changeSubtitleTrack
      );

      clearInterval(interval);

      setVideoBuffer([]);
      setAudioBuffer([]);
    };
  }, [channel]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    if (channels) {
      const _channel = channels.find((c) => c._id === channel?._id);

      if (!_channel?.active) {
        hls?.destroy();
        setSubtitleTracks([]);

        if (videoRef.current) {
          videoRef.current.removeEventListener("timeupdate", updateVideoTime);
          clearInterval(interval);
        }

        retryRef.current = 5;

        setErrorMessages(["Channel is not active"]);
      }

      if (_channel) {
      } else {
        hls?.destroy();
        setSubtitleTracks([]);

        if (videoRef.current) {
          videoRef.current.removeEventListener("timeupdate", updateVideoTime);
          clearInterval(interval);
        }

        retryRef.current = 5;

        setErrorMessages(["Channel has been deleted"]);
      }
    }
  }, [channels, channel]);

  return (
    <div className=" w-full h-full flex flex-col gap-1 relative">
      <div className=" absolute right-10 top-0 z-10 flex items-center gap-4">
        <Captions />
        <StreamSettings channel={channel!} />
      </div>

      <div className=" absolute left-10 top-0 z-10 flex items-center gap-4 bg-slate-500 bg-opacity-30 px-3 rounded-md">
        <h2 className=" text-lg"> {channel?.name}</h2>
      </div>

      {errorMessages && errorMessages.length > 0 && (
        <div
          className=" absolute max-md:w-full p-10 left-[50%] text-center z-[9] "
          style={{ transform: "translateX(-50%)", top: "calc(50% - 100px)" }}
        >
          {retryRef.current > 4 ? (
            <div className=" flex gap-2 items-center w-full">
              {" "}
              <AlertCircleIcon className=" animate-pulse" size={"32px"} />{" "}
              <h1 className=" font-semibold text-lg">{errorMessages}</h1>{" "}
            </div>
          ) : (
            <div className="w-full justify-center flex">
              <CircularProgress aria-label="Loading..." />
            </div>
          )}
        </div>
      )}

      <div
        className={"relative " + (isLoaded ? " aspect-video " : "")}
        onMouseEnter={() => {
          setShowControls(true);

          if (controlInterval.current) {
            clearTimeout(controlInterval.current);
          }

          controlInterval.current = setTimeout(() => {
            setShowControls(false);
          }, 3000);
        }}
        onMouseLeave={() => setShowControls(false)}
        onMouseMove={() => {
          setShowControls(true);

          if (controlInterval.current) {
            clearTimeout(controlInterval.current);
          }

          controlInterval.current = setTimeout(() => {
            setShowControls(false);
          }, 3000);
        }}
        onTouchStart={() => {
          setShowControls(true);

          if (controlInterval.current) {
            clearTimeout(controlInterval.current);
          }

          controlInterval.current = controlInterval.current = setTimeout(() => {
            setShowControls(false);
          }, 3000);
        }}
      >
        <video
          ref={videoRef}
          controls
          className="w-full h-full relative"
          muted={isMuted}
          style={{ display: isLoaded ? "block" : "none" }}
        />

        {showCurrentSubtitle(0) && showCurrentSubtitle(0)!.length > 0 && (
          <div
            className={
              "caption left-[50%]  absolute  bg-black bg-opacity-50 text-white text-center p-2 rounded " +
              (!showControls && !videoRef.current?.paused
                ? " bottom-0"
                : " bottom-[25%] md:bottom-[10%]")
            }
            style={{
              width: "calc(100%)",
              transform: "translateX(-50%)",
            }}
          >
            <p> {showCurrentSubtitle(0)} </p>
          </div>
        )}
      </div>

      {!isLoaded && (
        <Card className=" aspect-video space-y-5 p-2" radius="lg">
          <Skeleton className="rounded-lg">
            {channel && (
              <div className="aspect-video">
                <div className="h-full bg-gray-300 rounded-lg" />
              </div>
            )}
          </Skeleton>
        </Card>
      )}

      <div className="flex gap-2  overflow-scroll scrollbar-hide">
        <Dropdown isDisabled={!!authProvider.token !== true}>
          <DropdownTrigger>
            <Button
              color={"secondary"}
              disabled={!channel?.ready}
              size="sm"
              className=" min-w-20 max-w-20 h-7"
              // onClick={() => onUpdate(_channel)}
            >
              <CirclePlusIcon size={20} />
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="subtitles"
            selectedKeys={channelTranslations}
            selectionMode="multiple"
            variant="flat"
            onSelectionChange={async (v) => {
              const translates = Array.from(v)
                .filter((t) => t)
                .map((t) => t.toString());

              setChannelTranslations(translates);

              await updateChannel({
                ...channel!,
                translations: translates,
              });

              toast({
                title: "Translations",
                description: "Translations has been updated",
              });

              checkAndUpdateModel(translates[translates.length - 1]);

              loadVideo();
            }}
          >
            {langauges
              .filter((item) => channel?.audio[0].lang !== item.code)
              .map((sub) => (
                <DropdownItem key={sub.code} value={sub.code}>
                  {sub.language}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </Dropdown>
        {currentSubtitles
          .filter((item) => item.label !== channel?.audio[0].lang)
          .map((sub) => (
            <Tooltip key={sub.index} content="Change Language">
              <Button
                className=" min-w-20 h-7"
                color={
                  sub.label === (langs.length > 1 ? langs[1] : "")
                    ? "primary"
                    : "default"
                }
                disabled={!channel?.ready}
                size="sm"
                startContent={<LanguagesIcon size={16} />}
                onClick={() => {
                  if (
                    translateId.current &&
                    translateId.current.label == sub.label
                  ) {
                    translateId.current = null;

                    setLangs([langs[0]]);

                    return;
                  }

                  languageProvider.setChannelLanguage(
                    channel!._id,
                    sub.label,
                    langs
                  );

                  translateId.current = sub;

                  setLangs(
                    languageProvider.getChannelLanguages(
                      channel!._id,
                      currentSubtitles.map((item) => item.label),
                      channel!.audio[0].lang
                    )
                  );

                  fetchAllSubtitleFragments();
                }}
              >
                {sub.label.toUpperCase()}
              </Button>
            </Tooltip>
          ))}
      </div>

      {subtitleTracks.length > 0 &&
        subtitleTracks.map(
          (track, index) =>
            langs.includes(track.label) &&
            index != 0 && (
              <Card
                key={track.index}
                className={
                  " relative p-1 " +
                  (hideCaptions.includes(track.index) ? " h-8" : " min-h-14")
                }
                style={{
                  transition: "all 0.3s ease",
                }}
              >
                <div className="flex gap-2 absolute top-0 right-2 ">
                  {/* {voiceOver ? (
                    <Volume2 onClick={() => setVoiceOver(false)} />
                  ) : (
                    <VolumeX onClick={() => setVoiceOver(true)} />
                  )} */}

                  {/* {channel && (
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          className=" bg-transparent border-none"
                          size="sm"
                          variant="ghost"
                        >
                          {" "}
                          <DotsHorizontalIcon />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        disallowEmptySelection
                        aria-label="subtitles"
                        selectedKeys={langs.length > 1 ? [langs[1]] : []}
                        selectionMode="single"
                        variant="flat"
                        onSelectionChange={(v) => {
                          languageProvider.setChannelLanguage(
                            channel._id,
                            v.currentKey!,
                            langs
                          );

                          setLangs(
                            languageProvider.getChannelLanguages(
                              channel._id,
                              currentSubtitles.map((item) => item.label),
                              channel.audio[0].lang
                            )
                          );
                        }}
                      >
                        {channel &&
                          channel.translations.map((sub) => (
                            <DropdownItem key={sub} value={sub}>
                              {sub ? sub.toUpperCase() : ""}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </Dropdown>
                  )} */}
                  {/* <Tooltip content="Collapse caption box">
                    <Button
                      isIconOnly
                      className=" bg-opacity-50"
                      size="sm"
                      onClick={() => toggleHideCaption(track.index)}
                    >
                      {!hideCaptions.includes(track.index) ? (
                        <EyeOff size={12} />
                      ) : (
                        <EyeIcon size={12} />
                      )}
                    </Button>
                  </Tooltip>

                  <Tooltip content="Copy and download as a SRT">
                    <Button
                      isIconOnly
                      className=" bg-opacity-50"
                      size="sm"
                      onClick={() =>
                        copyAllCaptionsAsSubtitleSrtFormatInClipboard(
                          track.index
                        )
                      }
                    >
                      <Download size={12} />
                    </Button>
                  </Tooltip>
                  {isMultipleSubtitles && (
                    <Tooltip content="Add subtitle overlaid on the video">
                      <Button
                        isIconOnly
                        className=" bg-opacity-50"
                        size="sm"
                        onClick={() => setSelectedCaption(track.index)}
                      >
                        <CaptionsIcon size={12} />
                      </Button>
                    </Tooltip>
                  )} */}
                </div>
                <div className="flex gap-2 justify-between">
                  <div className=" text-primary-200 text-sm m-0">
                    {track.label.toUpperCase()}
                  </div>

                  <ButtonGroup
                    size="sm"
                    variant="ghost"
                    className=" rounded-none"
                  >
                    <Button
                      className=" w-4 h-4 rounded-none"
                      isIconOnly
                      size="sm"
                      variant="ghost"
                      onClick={() => handleFontSize("increase")}
                    >
                      {" "}
                      <Plus size={"14px"} />
                    </Button>
                    <Button
                      onClick={() => handleFontSize("decrease")}
                      className=" w-4 h-4"
                      variant="ghost"
                      isIconOnly
                      size="sm"
                    >
                      {" "}
                      <Minus size={"14px"} />
                    </Button>
                  </ButtonGroup>
                </div>
                <div
                  style={{ fontSize: fontSize }}
                  className={" " + (track.label === "ar" ? " text-right" : "")}
                >
                  <span>{showCurrentSubtitle(track.index)}</span>
                </div>
              </Card>
            )
        )}

      {channel && (
        <ScrollShadow orientation="horizontal" className="w-full">
          <div className="flex gap-4 p-2">
            {/* <Tooltip content="Edit Channel">
              <Button
                className="min-w-20"
                color="warning"
                size="sm"
                startContent={<EditIcon size={16} />}
                onClick={() => onUpdate(channel!)}
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip content="Delete Channel">
              <Button
                className=" min-w-20"
                color="danger"
                size="sm"
                startContent={<Trash2Icon size={16} />}
                onClick={() => onDelete(channel!)}
              >
                Delete
              </Button>
            </Tooltip> */}
          </div>
        </ScrollShadow>
      )}
    </div>
  );
}
