import React, { useState } from "react";
import { Button } from "@nextui-org/button";
import { CaptionsIcon, Copy, CopyPlus, PanelBottomClose } from "lucide-react";
import { Card, CardBody } from "@nextui-org/card";
import { Tabs, Tab } from "@nextui-org/tabs";
import { Tooltip } from "@nextui-org/tooltip";

import { Subtitle } from "./videoPlayer";
import { useToast } from "./ui/use-toast";

import useAppStore from "@/store/store";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { copyToClipboard } from "@/helpers/helpers";

export default function Captions() {
  const captions = useAppStore((state) => state.captions);
  const currentTime = useAppStore((state) => state.currentTime);
  const scrollToRef = React.useRef<HTMLDivElement>(null); // Create a ref to scroll to the active caption
  const videoPlayer = useAppStore((state) => state.videoPlayer);

  const [currentLang, setCurrentLang] = useState<string>("en");

  const { toast } = useToast();

  const [open, setOpen] = React.useState(false);

  async function copyAllCaptionsInClipboard(language: string) {
    const allCaptions = getEnglishCaptions(language)?.map(
      (caption) => caption.text
    );

    if (allCaptions) {
      await copyToClipboard(allCaptions?.join("\n"));

      downloadFile(allCaptions.join("\n"), `captions-${language}.txt`);

      if (toast) {
        toast({
          title: "Download",
          description: "All captions have been downloaded as a .txt file",
        });
      }
    }
  }

  function formatTime(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${hours}:${minutes}:${seconds}`;
  }

  async function copyAllCaptionsAsSubtitleSrtFormatInClipboard(
    language: string
  ) {
    const allCaptions = getEnglishCaptions(language)?.map(
      (caption, index) =>
        `${index + 1}\n${formatTime(caption.start)} --> ${formatTime(
          caption.end
        )}\n${caption.text}\n\n`
    );

    if (allCaptions) {
      await copyToClipboard(allCaptions?.join("\n"));

      downloadFile(allCaptions.join("\n"), `captions-${language}.srt`);

      if (toast) {
        toast({
          title: "Downloaded",
          description:
            "All captions with timecodes have been downloaded as a .srt file",
        });
      }
    }
  }

  function downloadFile(text: string, filename: string) {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });

    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  function getEnglishCaptions(language = "en") {
    const caption = captions.find((c) => c.label === language);

    return caption?.subtitles;
  }

  function captionIsActive(lan = "en") {
    let subtitleNext = getEnglishCaptions(lan)?.find(
      (s) => s.start > currentTime
    );

    let active = getEnglishCaptions(lan)?.reduce((acc, s) => {
      if (
        s.text.trim().length > 0 &&
        s.start <= currentTime &&
        (subtitleNext ? subtitleNext.start > currentTime : true)
      ) {
        return s;
      }

      return acc;
    }, getEnglishCaptions(lan)?.[0]);
    // const active = getEnglishCaptions(lan)?.find(
    //   (caption) => caption.start <= currentTime && caption.end >= currentTime
    // );

    // Scroll to the active caption
    setTimeout(() => {
      if (active && scrollToRef.current) {
        scrollToRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);

    return active;
  }

  function seekToCaption(caption: Subtitle) {
    if (videoPlayer) {
      if (videoPlayer.duration >= caption.end) {
        videoPlayer.currentTime = caption.start;
      }
    }
  }

  return (
    <Drawer modal={false} open={open}>
      <DrawerTrigger asChild>
        <Tooltip content="Show captions list section">
          <Button
            isIconOnly
            className=" bg-opacity-40"
            size="sm"
            onClick={() => setOpen(!open)}
          >
            <CaptionsIcon />
          </Button>
        </Tooltip>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mx-auto w-full max-w-2xl relative min-h-[300px] max-h-[300px] max-sm:h-[120px] max-sm:max-h-[120px]">
          <div className="p-4 pb-0 min-h-[300px] max-h-[300px] max-sm:h-[120px] max-sm:max-h-[120px]">
            <div className="flex items-center justify-center space-x-2">
              <div className="flex w-full flex-col">
                <div className=" flex gap-2 justify-end mb-2">
                  <Tooltip content="Copy and Download captions with Timecode">
                    <Button
                      isIconOnly
                      size="sm"
                      onClick={() =>
                        copyAllCaptionsAsSubtitleSrtFormatInClipboard(
                          currentLang
                        )
                      }
                    >
                      {" "}
                      <CopyPlus size={16} />{" "}
                    </Button>
                  </Tooltip>
                  <Tooltip content="Copy and Download captions">
                    <Button
                      isIconOnly
                      size="sm"
                      onClick={() => copyAllCaptionsInClipboard(currentLang)}
                    >
                      {" "}
                      <Copy size={16} />
                    </Button>
                  </Tooltip>
                </div>
                <Tabs
                  fullWidth
                  aria-label="Options"
                  onSelectionChange={(v) => setCurrentLang(v as string)}
                >
                  {captions.map((_caption) => {
                    return (
                      <Tab
                        key={_caption.label}
                        title={_caption.label.toUpperCase()}
                      >
                        <div
                          className="overflow-y-scroll max-h-[300px]"
                          dir={_caption.label === "ar" ? "rtl" : "ltr"}
                        >
                          {getEnglishCaptions(_caption.label)?.map(
                            (caption) => {
                              return (
                                <Card
                                  dir={_caption.label === "ar" ? "rtl" : "ltr"}
                                  key={caption.text + caption.start}
                                  ref={
                                    captionIsActive(_caption.label)?.text ===
                                    caption.text
                                      ? scrollToRef
                                      : null
                                  }
                                  className={
                                    "mb-4 cursor-pointer " +
                                    (captionIsActive(_caption.label)?.text ===
                                    caption.text
                                      ? " bg-primary-100"
                                      : "")
                                  }
                                  isBlurred={
                                    videoPlayer &&
                                    captionIsActive(_caption.label) &&
                                    captionIsActive(_caption.label)!.end >
                                      videoPlayer?.duration
                                  }
                                  isPressable={true}
                                  onClick={() => seekToCaption(caption)}
                                >
                                  <CardBody>{caption.text}</CardBody>
                                </Card>
                              );
                            }
                          )}
                        </div>
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
          <DrawerFooter>
            <DrawerClose>
              <Button
                color="warning"
                size="sm"
                startContent={<PanelBottomClose />}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
