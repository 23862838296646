import { useState, useEffect } from "react";
import { Select, SelectItem } from "@nextui-org/select";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/modal";
import { LucideSettings2 } from "lucide-react";
import { Button } from "@nextui-org/button";
import { Tooltip } from "@nextui-org/tooltip";

import { useToast } from "./ui/use-toast";

import { Channel, ChannelProvider } from "@/proivder/channelProvider";
import useAppStore from "@/store/store";

interface StreamSettingsProps {
  channel: Channel;
}

export default function StreamSettings({ channel }: StreamSettingsProps) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [audioLanguage, setAudioLanguage] = useState<string>("en");

  const { toast } = useToast();

  const languages = useAppStore(v => v.languages);

  function saveChanges() {
    ChannelProvider.updateChannel(channel.unique_name, {
      ...channel,
      audio: channel.audio.map((audio) => ({
        ...audio,
        lang: audioLanguage,
      })),
    });

    toast({
      title: "Saved",
      description: "Settings have been saved successfully",
    });

    onClose();
  }

  useEffect(() => {
    if (channel && channel.audio) {
      setAudioLanguage(channel.audio[0].lang);
    }
  }, [isOpen, channel]);

  return (
    <>
      <Tooltip content="Stream Settings">
        <LucideSettings2
          className="cursor-pointer"
          size={20}
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      <Modal
        backdrop="opaque"
        classNames={{
          body: "py-6",
          backdrop: "bg-[#292f46]/50 backdrop-opacity-40",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
        isOpen={isOpen}
        radius="lg"
        onClose={onClose}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {() => (
            <>
              <ModalBody>
                <div className="flex mb-4 mt-2" />
                <h3>Main Audio Language</h3>
                <p className=" text-xs">
                  {" "}
                  If you think the channel language is wrong, change this option
                </p>
                <Select
                  defaultSelectedKeys={[audioLanguage]}
                  value={audioLanguage}
                  onChange={(value) => setAudioLanguage(value.target.value)}
                >
                  {languages.map((language) => (
                    <SelectItem key={language.code} value={language.code}>
                      {language.language}
                    </SelectItem>
                  ))}
                </Select>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={saveChanges}>
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
