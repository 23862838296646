import Keycloak from "keycloak-js";

export class AuthProvider {
  private static instance: AuthProvider;
  private keycloak: Keycloak;

  private constructor() {
    // constructor logic
    this.keycloak = new Keycloak({
      url: "https://api.ovideo.app/auth",
      realm: "emsbt",
      clientId: "emsbt_web",
    });
  }

  static getInstance(): AuthProvider {
    if (!AuthProvider.instance) {
      AuthProvider.instance = new AuthProvider();
      AuthProvider.instance.keycloak.init({
        onLoad: "check-sso",
        checkLoginIframe: true,
        
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      });

      this.instance.setupTokenRefresh();
    }

    return AuthProvider.instance;
  }

  async login() {
    // login logic
    await this.keycloak.login({
      // onLoad: "login-required",
      //   checkLoginIframe: false,
      redirectUri: window.location.origin,
    });
  }

  async register() {
    // login logic
    await this.keycloak.register({
      // onLoad: "login-required",
      //   checkLoginIframe: false,
      redirectUri: window.location.origin,
    });
  }

  async logout() {
    this.keycloak.logout({
      redirectUri: window.location.origin,
    });
  }

  get userIsLoggedIn() {
    return this.keycloak.authenticated;
  }

  get token() {
    return this.keycloak.token;
  }

  onAuthentificationChange(callback: (authenticated: boolean) => void) {
    this.keycloak.onAuthLogout = () => callback(false);
    this.keycloak.onAuthSuccess = () => callback(true);
    this.keycloak.onAuthRefreshSuccess = () => callback(true);
  }

  private hashString(str: string) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);

      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }

    return hash.toString(16); // Convert to hexadecimal string
  }

  setupTokenRefresh() {
    // const refreshInterval = setInterval(() => {
    //   this.keycloak
    //     .updateToken(30) // Refresh if the token will expire in 30 seconds
    //     .then((refreshed) => {
    //       if (refreshed) {
    //         console.log("Token successfully refreshed");
    //       } else {
    //         console.log("Token is still valid");
    //       }
    //     })
    //     .catch(() => {
    //       console.error("Failed to refresh the token, logging out...");
    //       this.keycloak.logout();
    //     });
    // }, 60000); // Check every 60 seconds

    // return () => clearInterval(refreshInterval); // Cleanup on component unmount
  }

  uniqueId() {
    const canvas = document.createElement("canvas");
    const gl =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    const userAgent = navigator.userAgent;
    const screenResolution = `${screen.width}x${screen.height}`;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = navigator.language;
    const platform = navigator.platform;
    const plugins = Array.from(navigator.plugins)
      .map((p) => p.name)
      .join(",");

    // Generate a WebGL fingerprint
    const glFingerprint = gl
      ? gl.canvas.getContext("webgl")?.getExtension("WEBGL_debug_renderer_info")
      : "";

    // Combine all the components into a single string
    const fingerprintComponents = [
      userAgent,
      screenResolution,
      timeZone,
      language,
      platform,
      plugins,
      glFingerprint,
    ].join(" | ");

    // Hash the combined components
    return this.hashString(fingerprintComponents);
  }
}
