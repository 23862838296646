import React from "react";
import { PauseIcon, PlayIcon, ShieldAlert, SquarePlay } from "lucide-react";
import { Button } from "@nextui-org/button";
import { Card } from "@nextui-org/card";

import { Progress } from "@nextui-org/progress";

import ActionMenu from "./actionMenu";
import { useToast } from "./ui/use-toast";
import { Channel, ChannelProvider, Status } from "@/proivder/channelProvider";
import useAppStore from "@/store/store";

import { copyToClipboard } from "@/helpers/helpers";
import { BASE_VIDEO_URL } from "@/constants/urls";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

interface StreamListProps {
  items: Channel[];
  channel: Channel;
  onPlay?: () => void;
  onChannel: (channel: Channel) => void;
  onUpdate: (channel: Channel) => void;
  onDelete: (channel: Channel) => void;
  onExport: (channel: Channel) => void;
  videoPlayer: HTMLVideoElement | null;
}

const StreamList: React.FC<StreamListProps> = ({
  items,
  videoPlayer,
  channel,
  onChannel,
  onUpdate,
  onDelete,
  onPlay,
  onExport
}) => {
  // const transitions = useTransition(items, {
  //   keys: (item) => item.sourceId,
  //   trail: 400 / items.length,
  //   from: { opacity: 0, scale: 0 },
  //   enter: { opacity: 1, scale: 1 },
  //   leave: { opacity: 0, scale: 0 },
  // });

  const currentSubtitles = useAppStore((state) => state.currentSubtitles);

  const languageProvider = useAppStore((state) => state.languageProvider);

  const [, setLanguages] = React.useState<string[]>([]);

  const { toast } = useToast();

  React.useEffect(() => {
    // console.log(channel.audio[0].lang);
    if (channel) {
      setLanguages(
        languageProvider.getChannelLanguages(
          channel._id,
          currentSubtitles.map((item) => item.label),
          channel.audio[0].lang
        )
      );
    }

    //console.log(items[items.length - 1].name, items[items.length - 1].status, items[items.length - 1].progress);
  }, [languageProvider, channel, items]);

  async function activateChannel(channel: Channel, active: boolean) {
    await ChannelProvider.updateChannel(
      channel.unique_name,

      {
        ...channel,
        active: active,
        status: active ? Status.Queued : channel.status,
      }
    );

    if (toast) {
      toast({
        title: channel.name,
        description: "Channel " + (active ? "Activated" : "Deactivated"),
      });
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 w-full">
      {items.map((_channel) => (
        <ActionMenu
          isContextMenu={true}
          key={_channel._id}
          isPlaying={_channel?._id == channel?._id}
          isActive={
            _channel.active &&
            _channel.status == Status.Recording &&
            _channel.ready &&
            _channel.progress == 100
          }
          onActivate={() => activateChannel(_channel, true)}
          onCopy={async () => {
            await copyToClipboard(BASE_VIDEO_URL + _channel.unique_name);

            toast({
              title: "Copied",
              description: "Channel URL copied to clipboard",
              duration: 2000,
            });
          }}
          onDeactivate={() => activateChannel(_channel, false)}
          onDelete={() => onDelete(_channel)}
          onEdit={() => onUpdate(_channel)}
          onPlay={() => onChannel(_channel)}
          onExport={() => onExport(_channel)}
        >
          <Card
            key={_channel._id}
            className={
              "rounded-lg cursor-pointer w-full " +
              (channel?._id == _channel._id ? " bg-purple-700" : "") +
              (_channel.status != Status.Recording ? " bg-opacity-50" : "")
            }
            isPressable={
              _channel.active &&
              _channel.status == Status.Recording &&
              _channel.ready &&
              _channel.progress == 100
            }
            style={{ transition: "all ease 0.3s" }}
            onClick={() => {
              if (_channel?._id == channel?._id && !videoPlayer?.paused) {
                videoPlayer?.pause();
              } else {
                onChannel(_channel);
                videoPlayer?.play();
              }

              if (onPlay) onPlay();
            }}
          >
            <div className="flex gap-2 p-2 w-full justify-between">
              <div className="flex  gap-2 w-full justify-between items-center">
                <div className="flex flex-col items-start w-full overflow-hidden justify-between  ">
                  <h3 className="flex-grow  text-sm font-semibold whitespace-nowrap overflow-ellipsis">
                    {_channel.name}
                  </h3>
                  <h3 className="text-[10px] font-semibold flex gap-1 items-center text-slate-300">
                    <SquarePlay size={"16px"} />{" "}
                    {_channel.sourceType.toUpperCase()}
                  </h3>
                  {/* {_channel.active ? <Check color="green" /> : null} */}
                </div>
                {/* <div className="flex gap-2">
                    <p className="text-sm">{_channel.sourceType}</p>
                    <p className="text-sm">{_channel.transcode.resolution}</p>
                  </div> */}
                <div className="flex justify-between gap-2 items-center">
                  <Button
                    className=" w-24"
                    color={_channel.failed_status ? "danger" : "default"}
                    disabled={
                      !_channel.ready ||
                      !_channel.active ||
                      _channel.progress < 100 ||
                      _channel.status != Status.Recording
                    }
                    isDisabled={
                      !_channel.ready ||
                      !_channel.active ||
                      _channel.status != Status.Recording
                    }
                    size="sm"
                    startContent={
                      _channel.status == Status.Queued ||
                      _channel.status == Status.Initialized ||
                      (_channel.progress < 100 && _channel.progress > 0) ? (
                        <div className="relative">
                          <div className="w-4 h-4 bg-white rounded-full animate-ping" />
                          <div className="absolute top-0 left-0 w-4 h-4 bg-white rounded-full" />
                        </div>
                      ) : !_channel?.ready ||
                        !_channel?.active ||
                        _channel?.status != Status.Recording ? (
                        <ShieldAlert />
                      ) : _channel?._id == channel?._id &&
                        !videoPlayer?.paused ? (
                        <PauseIcon />
                      ) : (
                        _channel?.ready &&
                        _channel?.active &&
                        _channel?.status == Status.Recording && <PlayIcon />
                      )
                    }
                    onClick={() => {
                      if (
                        _channel?._id == channel?._id &&
                        !videoPlayer?.paused
                      ) {
                        videoPlayer?.pause();
                      } else {
                        onChannel(_channel);
                      }
                    }}
                  >
                    {_channel?.failed_status == "failed"
                      ? _channel.failed_status
                      : !_channel?.active
                        ? "Inactive"
                        : _channel?.status != Status.Recording
                          ? Status[_channel?.status]
                          : _channel?._id == channel?._id
                            ? "Playing"
                            : "Play"}
                  </Button>

                  <ActionMenu
                    key={_channel._id}
                    isActive={
                      _channel.active &&
                      _channel.status == Status.Recording &&
                      _channel.ready &&
                      _channel.progress == 100
                    }
                    isPlaying={_channel?._id == channel?._id}
                    onActivate={() => activateChannel(_channel, true)}
                    onExport={() => onExport(_channel)}
                    onCopy={async () => {
                      await copyToClipboard(
                        BASE_VIDEO_URL + _channel.unique_name
                      );

                      toast({
                        title: "Copied",
                        description: "Channel URL copied to clipboard",
                        duration: 2000,
                      });
                    }}
                    onDeactivate={() => activateChannel(_channel, false)}
                    onDelete={() => onDelete(_channel)}
                    onEdit={() => onUpdate(_channel)}
                    onPlay={() => onChannel(_channel)}
                  >
                    <Button
                      isIconOnly
                      className=" bg-transparent border-none"
                      size="sm"
                      variant="ghost"
                    >
                      <DotsVerticalIcon />
                    </Button>
                  </ActionMenu>
                </div>
              </div>
            </div>

            {_channel && _channel.progress < 100 ? (
              <Progress maxValue={100} size="sm" value={_channel.progress} />
            ) : null}
          </Card>
        </ActionMenu>
      ))}
    </div>
  );
};

export default StreamList;
