import request from "./request";

export interface Channels {
  total: number;
  content: Channel[];
}

export interface Models {
  content: TranslateModel[];
  total: number;
}

export interface Channel {
  _id: number;
  active: boolean;
  audio: Audio[];
  crypt: Crypt;
  name: string;
  pcrPid: number;
  record: Record;
  scrambled: boolean;
  serviceId: number;
  transcode: Transcode;
  video: Video;
  status: Status;
  failed_status?: string;
  unique_name: string;
  sourceType: "mpts" | "youtube";
  sourceId: number;
  nodeIdRec: number;
  nodeIdTrc: number;
  baseNodeTrc: number;
  baseNodeRec: number;
  transcribe: boolean;
  ready: boolean;
  queue_wait: number;
  progress: number;
  translations: string[];
}

export interface Audio {
  id: number;
  lang: string;
  sample_rate: number;
  type: string;
}

export interface Crypt {
  iv: string;
  key: string;
  type: string;
}

export interface Record {
  active: boolean;
  audio: any[]; // Replace with the appropriate type
  duration: number;
}

export interface Transcode {
  active: boolean;
  bitrate: number;
  duration: number;
  resolution: string;
  profiles: Profile[];
}

export interface Profile {
  name: string;
  acodec: string;
  bitrate: number;
  fps: number;
  width: number;
  height: number;
  vcodec: string;
  audiorate: number;
  preset: string;
  url: string;
  transcode: boolean;
  active: boolean;
}

export interface Video {
  frame_rate: number;
  height: number;
  id: number;
  type: string;
  width: number;
}

export interface Source {
  type?: "mpts" | "youtube";
  url: string;
  name: string;
  _id?: number;
  active?: boolean;
}

export interface TranslateModel {
  cuda: number;
  key: string;
  load: boolean;
  model_name: string;
  port: number;
}

export interface Language {
  code: string;
  language: string;
}

export enum Status {
  Unknown = 0,
  Queued = 1,
  Initialized = 2,
  Recording = 3,
  Failed = 4,
}

export class ChannelProvider {
  static async getChannels() {
    const response = await request.get<Channels>("/config/channels");

    return response.data;
  }

  static async getSources() {
    const response = await request.get<Channels>("/config/sources");

    return response.data;
  }

  static async createChannel(data: Source) {
    const response = await request.post<Channel>("/config/sources", data);

    return response.data;
  }

  static async updateSource(data: Source) {
    const response = await request.put<Channel>("/config/sources", data);

    return response.data;
  }

  static async updateChannel(unique_name: string, data: Channel) {
    const response = await request.put<Channel>(
      "/config/channel/" + unique_name,
      data
    );

    return response.data;
  }

  static async deleteChannel(sourceId: number) {
    const response = await request.delete<Channel>(
      `/config/sources?_id=${sourceId}`
    );

    return response.data;
  }

  static async getLanguages() {
    const response = await request.get<{
      content: Language[];
    }>("/config/languages");

    return response.data;
  }

  static async getTranslateModels() {
    const response = await request.get<Models>("/config/translations");

    return response.data.content;
  }

  static async updateTranslateModels(model: TranslateModel) {
    const response = await request.put<Models>("/config/translations", model);

    return response.data.content;
  }
}
