import axios, { AxiosError } from "axios";
import { AuthProvider } from "./authProvider";

const BASE_URL = "https://api.ovideo.app/emsbt/";

//const notify = () => toast("Here is your toast.");

const authProvider = AuthProvider.getInstance();

export interface ErrorResponse {
  detail: string;
  status: number;
  title: string;
  traceId: string;
}

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    // set base url



    config.baseURL = BASE_URL;

    if (config.url?.includes("version")) return config;

    if (config.url?.includes("login")) return config;
    // check token and get token from locastorage
    let token = authProvider.token;

    if (token) {
      // check if url has query params
      if (config.url?.includes("?")) {
        config.url = config.url + `&token=${token}`;
      } else {
        config.url = config.url + `?token=${token}`;
      }
    }

    // set device id to header
    config.headers["device-id"] = authProvider.uniqueId();

    // append token to url as query param ?token=token or &token=token

    // set token to header
    return config;
  },
  function (error: AxiosError) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (!error.response?.status) return Promise.reject(null);

    if (error.config?.url === "Login/checkProjectAccess")
      return Promise.reject(error);

    if (error.response?.status === 401) {
      if (error.config?.method === "post") {
        // toast.error("You have no permission for this action.");
        return;
      }

      // window.location.href = "/login";
      return Promise.reject(error);
    }
    const errorResponse = error.response
      ? (error.response!.data as ErrorResponse)
      : null;

    if (error.response?.status === 406) {
      if (errorResponse && errorResponse.detail === "Invalid token!") {
        window.location.href = "/login";
        //toast.error(errorResponse.detail);
      }
    }
    if (errorResponse && errorResponse.detail) {
      // toast.error(errorResponse.detail);
    } else {
      // if (error.response?.status !== 404) toast.error("An error ocurred");
    }

    return Promise.reject(errorResponse);
  }
);

export default axios;
