import { AuthProvider } from "@/proivder/authProvider";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import {
  Copy,
  Download,
  Edit,
  Play,
  ShieldAlert,
  ShieldCheck,
  Trash2,
} from "lucide-react";
import React from "react";

interface ActionMenuProps {
  children: React.ReactNode;
  isActive: boolean;
  isPlaying?: boolean;
  isContextMenu?: boolean;
  onPlay: () => void;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
  onActivate: () => void;
  onDeactivate: () => void;
  onExport: () => void;
}

export default function ActionMenu({
  children,
  isActive,
  onCopy,
  onDelete,
  onEdit,
  onPlay,
  onActivate,
  onDeactivate,
  isContextMenu,
  isPlaying,
  onExport,
}: ActionMenuProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const authProvider = AuthProvider.getInstance();

  return (
    <Dropdown
      shouldBlockScroll
      isOpen={isOpen}
      trigger="longPress"
      onOpenChange={(open) => {
        if (!open) {
          setIsOpen(open);
        }
      }}
    >
      <DropdownTrigger
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isContextMenu) {
            setIsOpen(true);
          }
          // setIsOpen(true);
        }}
        onContextMenu={(e) => {
          if (isContextMenu) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }
        }}
      >
        {children}
      </DropdownTrigger>
      <DropdownMenu
        disabledKeys={
          !authProvider.userIsLoggedIn
            ? [
                "copy",
                "export",
                "edit",
                "active",
                "deactivate",
                "delete",
                isActive ? "" : "play",
              ]
            : isActive
              ? [isPlaying ? "" : "export"]
              : ["play", "copy", "export"]
        }
        variant="faded"
        aria-label="Dropdown menu with description"
      >
        <DropdownItem
          key="play"
          description="Play stream"
          startContent={<Play />}
          onPress={onPlay}
        >
          Play
        </DropdownItem>
        <DropdownItem
          key="edit"
          description="Edit the stream"
          startContent={<Edit />}
          onPress={onEdit}
        >
          Edit
        </DropdownItem>
        <DropdownItem
          key="copy"
          description="Copy the stream URL"
          startContent={<Copy />}
          onPress={onCopy}
        >
          Copy
        </DropdownItem>

        <DropdownItem
          key="export"
          description="Export the stream"
          startContent={<Download />}
          onPress={onExport}
        >
          Export
        </DropdownItem>

        {!isActive ? (
          <DropdownItem
            key="active"
            description="Activate the stream"
            startContent={<ShieldCheck />}
            onPress={onActivate}
          >
            Activate
          </DropdownItem>
        ) : (
          <DropdownItem
            key="deactivate"
            description="Deactivate the stream"
            startContent={<ShieldAlert />}
            onPress={onDeactivate}
          >
            Deactivate
          </DropdownItem>
        )}

        <DropdownItem
          key="delete"
          className="text-danger"
          color="danger"
          description="Delete the stream"
          startContent={<Trash2 />}
          onPress={onDelete}
        >
          Delete file
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
