import { create } from "zustand";

import {
  Channel,
  ChannelProvider,
  Language,
  Source,
  Status,
} from "@/proivder/channelProvider";
import { SubtitleTrack } from "@/components/videoPlayer";
import { LanguageProvider } from "@/proivder/languageProvider";

type AppState = {
  getChannels: () => Promise<Channel[]>;
  createChannel: (data: Source) => Promise<{ _id: number }>;
  updateChannel: (data: Channel) => Promise<void>;
  addCaptions: (CaptionsOffIcon: SubtitleTrack[]) => void;
  setCurrentTime: (time: number) => void;
  setVideoPlayer: (player: HTMLVideoElement) => void;
  setMultipleSubtitles: (isMultipleSubtitles: boolean) => void;
  getLanguages: () => Promise<void>;

  setVideoBuffer: (buffer: Uint8Array[]) => void;
  setAudioBuffer: (buffer: Uint8Array[]) => void;

  setCurrentSubtitles: (subtitles: SubtitleTrack[]) => void;

  setChannel: (channel: Channel) => void;

  setEditItem: (item: Channel | null) => void;

  channels: Channel[];
  captions: SubtitleTrack[];
  currentTime: number;
  videoPlayer?: HTMLVideoElement;

  isMultipleSubtitles: boolean;

  languageProvider: LanguageProvider;

  languages: Language[];

  videoBuffer: Uint8Array[];
  audioBuffer: Uint8Array[];

  currentSubtitles: SubtitleTrack[];

  channel?: Channel;

  editItem: Channel | null;
};

/**
 * Custom hook for managing the application state.
 * @returns An object containing the state and methods to manipulate it.
 */
/**
 * Creates and initializes the application store.
 * @param set - A function provided by the state library to update the state.
 * @returns An object representing the application store.
 */
const useAppStore = create<AppState>((set) => ({
  channels: [],
  captions: [],
  currentTime: 0,
  isMultipleSubtitles: true,
  languageProvider: new LanguageProvider(),
  languages: [],
  videoBuffer: [],
  audioBuffer: [],

  currentSubtitles: [],

  editItem: null,

  /**
   * Retrieves the list of channels asynchronously and updates the state.
   * @returns A Promise that resolves when the channels are fetched and the state is updated.
   */
  getChannels: async () => {
    const channels = await ChannelProvider.getChannels();

    channels.content.sort((a, b) => {
      if (
        a.progress > 0 &&
        a.progress < 100 &&
        (b.progress === 0 || b.progress === 100)
      ) {
        return -1; // a comes before b
      } else if (
        (a.progress === 0 || a.progress === 100) &&
        b.progress > 0 &&
        b.progress < 100
      ) {
        return 1; // b comes before a
      } else if (a.status === Status.Queued && b.active) {
        return -1; // a comes before b
      } else if (a.active && b.status === Status.Queued) {
        return 1;
        // b comes before a
      } else if (a.active && !b.active) {
        return -1;
      } else {
        return 0; // no change in order
      }
    });

    set({ channels: [...channels.content] });

    
    return channels.content;
  },

  /**
   * Creates a new channel asynchronously and updates the state.
   * @param data - The data for creating the channel.
   * @returns A Promise that resolves when the channel is created and the state is updated.
   */
  createChannel: async (data) => {
    const result = await ChannelProvider.createChannel(data);

    return result;
  },

  /**
   * Updates an existing channel asynchronously and updates the state.
   * @param data - The data for updating the channel.
   * @returns A Promise that resolves when the channel is updated and the state is updated.
   */
  updateChannel: async (data) => {
    await ChannelProvider.updateChannel(data.unique_name, data);
  },

  /**
   * Sets the captions for the current channel.
   * @param captions - The captions to be set.
   */
  addCaptions: (captions) => {
    set({ captions });
  },

  /**
   * Sets the current time in the player.
   * @param time - The current time to be set.
   */
  setCurrentTime: (time) => {
    set({ currentTime: time });
  },

  setVideoPlayer: (player) => {
    set({ videoPlayer: player });
  },

  setMultipleSubtitles: (isMultipleSubtitles) => {
    set({ isMultipleSubtitles });
  },

  getLanguages: async () => {
    set((state) => {
      if (state.languages.length === 0) {
        ChannelProvider.getLanguages().then((languages) => {
          set({ languages: languages.content });
        });
      }

      return state;
    });
  },

  setVideoBuffer: (buffer) => {
    set({ videoBuffer: buffer });
  },
  setAudioBuffer: (buffer) => {
    set({ audioBuffer: buffer });
  },

  setCurrentSubtitles: (subtitles) => {
    set({ currentSubtitles: subtitles });
  },

  setChannel: (channel) => {
    set({ channel: channel });
  },

  setEditItem: (item) => {
    set({ editItem: item });
  },
}));

export default useAppStore;
